import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"
import { genderReverse } from "../../../utils/lyneUphelpers"

const LyneGenderSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0px 0px 10px;
`

const LyneGenderChooseText = styled.p`
  font-family: "Museo";
  font-weight: bold;
  font-size: 16px;
  line-height: 1.2;
  margin: 0px;
`

const LyneNoGenderText = styled.p`
  font-family: "Gotham Book";
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  margin: 0px;
  color: ${({ error }) => (error ? "#EF2C2C" : "#262626")};
  max-width: 420px;
`

const LyneGenderSectionBox = styled.div`
  width: 100%;
  display: flex;
  margin: 10px 0px;
`

const LyneGenderBox = styled.div`
  display: flex;
  width: 100%;
  max-width: 170px;
  padding: 3px;
  cursor: pointer;
  border: ${({ error, active }) =>
    error
      ? "1px solid #EF2C2C"
      : active
      ? "2px solid #5db9bc"
      : "0px solid #5db9bc"};
  border-radius: ${({ error, active }) =>
    error || active ? "12px 1px" : "0px"};
  margin: 0px 3px;

  :hover {
    border: 2px solid #5db9bc;
    border-radius: 12px 1px;
  }

  @media (max-width: 600px) {
    max-width: calc(50% - 6px);
  }
`

const LyneGenderBoxWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${({ active }) => (active ? "#D9F0F1" : "inherit")};
  border: ${({ active }) =>
    active ? "0.5px solid #fff" : "0.5px solid #262626"};
  border-radius: 12px 1px;
  font-weight: ${({ active }) => (active ? "bold" : "500")};
  overflow: hidden;
`

const LyneGenderTextBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 9px 9px 5px;

  @media (max-width: 360px) {
    padding: 9px 6px 5px;
  }
`

const LyneGenderText = styled.p`
  font-family: "Gotham Book";
  font-size: 14px;
  line-height: 1.2;
  margin: 0px;

  @media (max-width: 360px) {
    font-size: 13.5px;
  }
`

const LyneGenderImage = styled.img`
  display: flex;
  object-fit: contain;
  margin: 0px;
  width: 100%;
`

const LyneGenderSection = ({ intl, data, handleChange, gender, error, id }) => {
  const [activeIndex, setactiveIndex] = useState(null)
  const handleActive = index => {
    setactiveIndex(index)
    handleChange({ target: { name: "Gender", value: data[index] } })
  }
  useEffect(() => {
    if (gender) setactiveIndex(data.indexOf(genderReverse[intl.locale][gender]))
    else setactiveIndex(null)
  }, [gender])
  return (
    data && (
      <LyneGenderSectionWrapper id={id}>
        <LyneGenderChooseText
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({
              id: "lyne_selected_gender_text",
            }),
          }}
        />
        <LyneGenderSectionBox>
          {data.map((item, index) => (
            <LyneGenderBox
              key={index.toString()}
              onClick={() => handleActive(index)}
              active={activeIndex === index}
              error={error}
            >
              <LyneGenderBoxWrapper active={activeIndex === index}>
                <LyneGenderTextBox>
                  <LyneGenderText
                    dangerouslySetInnerHTML={{
                      __html: index == 0 ? "Femme >" : "Homme >",
                    }}
                  />
                </LyneGenderTextBox>
                {!gender && (
                  <LyneGenderImage
                    className="lazyload"
                    data-src={
                      index == 0
                        ? "https://static.percko.com/uploads/a561edb0-a816-4d03-a105-e380b97746c4.jpg"
                        : "https://static.percko.com/uploads/e4719548-d765-4b29-8a3c-314239a0d6e2.jpg"
                    }
                  />
                )}
              </LyneGenderBoxWrapper>
            </LyneGenderBox>
          ))}
        </LyneGenderSectionBox>
        {!gender && (
          <LyneNoGenderText
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: "lyne_no_gender_text",
              }),
            }}
            error={error}
          />
        )}
      </LyneGenderSectionWrapper>
    )
  )
}

export default injectIntl(LyneGenderSection)

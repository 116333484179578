import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"

const LyneProductTypeWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0px 0px 10px;
`

const TopBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0px;

  @media (max-width: 360px) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`

const LyneProductChooseText = styled.p`
  font-family: "Museo";
  font-weight: bold;
  font-size: 16px;
  line-height: 1.2;
  margin: 0px;
`

const LyneOldTag = styled.div`
  display: flex;
  align-self: flex-start;
  background: #131112;
  color: #fff;
  font-size: 16px;
  line-height: 1.2;
  font-family: "BebasNeueBold";
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 10px;
  padding: 5px 10px;
  border-radius: 10px 0px;

  @media (max-width: 360px) {
    margin: 0px 0px 10px;
  }
`

const LyneNoGenderText = styled.p`
  font-family: "Gotham Book";
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  margin: 0px;
  color: ${({ error }) => (error ? "#EF2C2C" : "#262626")};
  max-width: 420px;
`

const LyneProductTypeBox = styled.div`
  width: 100%;
  display: flex;
  margin: 10px 0px;
`

const LyneProductBox = styled.div`
  display: flex;
  width: 100%;
  max-width: 111px;
  padding: 3px;
  cursor: pointer;
  border: ${({ error, active }) =>
    error
      ? "1px solid #EF2C2C"
      : active
      ? "2px solid #5db9bc"
      : "0px solid #5db9bc"};
  border-radius: ${({ error, active }) =>
    error || active ? "12px 1px" : "0px"};
  margin: 0px 3px;

  :hover {
    border: 2px solid #5db9bc;
    border-radius: 12px 1px;
  }

  @media (max-width: 600px) {
    max-width: calc(50% - 6px);
  }
`

const LyneProductBoxWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${({ active }) => (active ? "#D9F0F1" : "inherit")};
  border: ${({ active }) =>
    active ? "0.5px solid #fff" : "0.5px solid #262626"};
  border-radius: 12px 1px;
  font-weight: ${({ active }) => (active ? "bold" : "500")};
  overflow: hidden;
`

const LyneProductTextBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 9px 9px 5px;

  @media (max-width: 360px) {
    padding: 9px 6px 5px;
  }
`

const LyneProductText = styled.p`
  font-family: "Gotham Book";
  font-size: 14px;
  line-height: 1.2;
  margin: 0px;

  span {
    display: block;
  }

  @media (max-width: 360px) {
    font-size: 13.5px;
  }
`

const LyneProductImage = styled.img`
  display: flex;
  object-fit: contain;
  margin: 0px;
  width: 100%;
`

const LyneProductType = ({
  intl,
  data,
  handleChange,
  productType,
  gender,
  error,
  page,
}) => {
  const [activeProduct, setActiveProduct] = useState(null)
  const handleActive = product => {
    setActiveProduct(product)
    handleChange(product)
  }
  useEffect(() => {
    if (productType) setActiveProduct(productType)
    else setActiveProduct(null)
  }, [productType])
  return (
    data && (
      <LyneProductTypeWrapper>
        <TopBox>
          <LyneProductChooseText
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: "lyne_select_gender_text",
              }),
            }}
          />
          {page == "old-lyne" && (
            <LyneOldTag
              dangerouslySetInnerHTML={{
                __html: "ANCIEN MODÈLE",
              }}
            />
          )}
        </TopBox>
        <LyneProductTypeBox>
          {data &&
            data.list.map((item, index) => (
              <LyneProductBox
                key={index.toString()}
                onClick={() => handleActive(item.name)}
                active={activeProduct === item.name}
                error={error}
              >
                <LyneProductBoxWrapper active={activeProduct === item.name}>
                  <LyneProductTextBox>
                    {gender === "Male" ? (
                      <LyneProductText
                        dangerouslySetInnerHTML={{
                          __html: item.male_title,
                        }}
                      />
                    ) : (
                      <LyneProductText
                        dangerouslySetInnerHTML={{
                          __html: item.female_title,
                        }}
                      />
                    )}
                  </LyneProductTextBox>
                  {gender === "Male" ? (
                    <LyneProductImage
                      alt={item.male_image_alt ? item.male_image_alt : ""}
                      src={item.male_image}
                    />
                  ) : (
                    <LyneProductImage
                      alt={item.female_image_alt ? item.female_image_alt : ""}
                      src={item.female_image}
                    />
                  )}
                </LyneProductBoxWrapper>
              </LyneProductBox>
            ))}
        </LyneProductTypeBox>
        {!gender && (
          <LyneNoGenderText
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: "lyne_no_gender_text",
              }),
            }}
            error={error}
          />
        )}
      </LyneProductTypeWrapper>
    )
  )
}

export default injectIntl(LyneProductType)
